import React, { useEffect, useState } from 'react'
import HeaderNull from './Layout'
import gipImage from "./assets/A360_GIP.jpg";
import { httpsCallable, HttpsCallableResult} from "firebase/functions";
import { functions } from "./firebase.config";
import { RequestInvoiceForm } from './components/RequestInvoiceForm'
import { PulseLoader } from 'react-spinners'
import { formatCurrency } from './util/formatCurrency';
import LineItem from './components/LineItem';
import AlertBox from './components/AlertBox';
import VirtualMembership from './components/renewal-membership/VirtualMembership';
import GIPMembership from './components/renewal-membership/GIPMembership';
import PatronMembership from './components/renewal-membership/PatronMembership';
import { DealFromHash } from './types/deal.type';
import { RenewalLevel } from './constants/data.enums';


//#region helper components
const Container = (props: {children: React.ReactNode}) => {
  return <div className="bg-white mt-16 md:mt-12">
    <main>
      {props.children}
    </main>
  </div>
}
//#endregion


export default function Payment() {
  const [showInvoice, setShowInvoice] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [isError, setIsError] = useState("")
  const [isPaymentError, setIsPaymentError] = useState("")
  const [isLoadingPage, setIsLoadingPage] = useState(true)
  const [deal, setDeal] = useState<DealFromHash>(Object.create(null))
  const [showDepositProduct, setShowDepositProduct] = useState(false)

  useEffect(() => {
    let theHash = window.location.pathname.substring(window.location.pathname.lastIndexOf('/') + 1)
    let params = new URLSearchParams(window.location.search);
    let sendParams = params.get('failed_pi') ? { hash: theHash, failed_pi:params.get('failed_pi')} : { hash: theHash};
    const getDeal = async () => {
    const response = await httpsCallable(functions, "getDealFromHash")(sendParams);
    return response.data
    }
    getDeal().then((data: any) => {
      if (data.failed_pi) {
        setIsPaymentError(`Error with payment: ${data.failed_pi.last_payment_error.message} Please contact your bank or try another payment method.`)
      }
      setDeal(data)
      setIsLoadingPage(false)
    }).catch((error) => {
      setIsLoadingPage(false)
      setIsError(error.message)
    })
    }, [])

  const goToStripe = async () => {
    setIsLoading(true)
    const activeResponse = await new Promise<{data:any}>(async (resolve) => {
        const response = await httpsCallable(
        functions,
        "initStripeCheckoutSession"
        )({ dealId: deal.deal.hubspot_deal_id, paying_deposit:showDepositProduct}).catch((error) => {
            return false;
        });
        resolve(response as HttpsCallableResult);
    });
        if(activeResponse.data.redirectUrl){
            window.location.href = activeResponse.data.redirectUrl
        }
    }

    const showInvoiceForm = () => {
        setShowInvoice(true)
    }

    const switchToDeposit = () => {
      setShowDepositProduct(!showDepositProduct)
    }

  const event_renewal_level = deal.deal?.event_renewal_level
  if (isLoadingPage) {
    return <Container>
      <div className="text-center mt-[300px]"><PulseLoader color="#000" size={40} /></div>
    </Container>
  } else if (deal.deal?.event_renewal && deal.deal.event_renewal === "true" && event_renewal_level) {
    return (
      <Container>
        {event_renewal_level === RenewalLevel.VIRTUAL && <VirtualMembership deal={deal.deal} />}
        {event_renewal_level === RenewalLevel.GIP && <GIPMembership deal={deal.deal} />}
        {event_renewal_level === RenewalLevel.PATRON && <PatronMembership deal={deal.deal} />}
      </Container>
    )
  } else {
    return (
      <Container>
        <>
        {
            isError &&
            <AlertBox
              type='success'
              title={isError}
              message={''}
              actionBtns={{
                postitiveBtnText: 'Login to AbundanceHub',
                positiveBtnClick(e) {
                  e.preventDefault();
                  window.open('https://hub.a360.com/', '_blank');
                },
              }}
            />
      }
      {!isLoadingPage && !isError &&
       <div className="mx-auto max-w-2xl px-4 py-16 sm:px-6 sm:py-12 lg:px-0">
          <h1 className="text-center text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">{!showInvoice ? "Order Summary" : "Request Invoice for payment"}</h1>
          {isPaymentError && 
            <div className="mx-auto max-w-2xl px-4 py-6 pb:0 sm:px-6 sm:py-12 sm:pb-0 lg:px-0">
              <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative" role="alert">
                <strong className="font-normal">{isPaymentError}</strong>
              </div>
            </div>
              }
          {showInvoice && <RequestInvoiceForm deal={deal} setShowInvoice={setShowInvoice}/>}
          {!showInvoice && 
          <form className="mt-12">
            <section aria-labelledby="cart-heading">
              <ul role="list" className="divide-y divide-gray-200 border-b border-t border-gray-200">
                {deal.lineItems && deal.lineItems.map((product:any) => {
                  if(showDepositProduct){
                    return <LineItem name={product.deposit_product.description} description={""} price={product.deposit_product.price} id={product.deposit_product.hubspot_id} />
                  }else{
                    return <LineItem name={product.product.description} description={""} price={product.product.price} id={product.hs_product_id} />
                  }
                })}
              </ul>
            </section>

            {/* Order summary */}
            <section aria-labelledby="summary-heading" className="mt-5">
              <h2 id="summary-heading" className="sr-only">
                Order summary
              </h2>

              <div>
                {deal.lineItems && deal.lineItems[0].discount && !showDepositProduct &&
              <dl className="space-y-4 mb-3">
                  <div className="flex items-center justify-between">
                    <dt className="text-base font-medium text-gray-900">Discount</dt>
                    <dd className="ml-4 text-base font-medium text-green-600">- {formatCurrency(Number(deal.lineItems[0].discount.amount))}</dd>
                  </div>
                </dl>}
                {deal.lineItems && 
                <dl className="space-y-4">
                  <div className="flex items-center justify-between">
                    <dt className="text-base font-medium text-gray-900">Total</dt>
                    <dd className="ml-4 text-base font-medium text-gray-900">{!showDepositProduct ? formatCurrency(Number(deal.lineItems[0].amount)) : formatCurrency(deal.lineItems[0].deposit_product.price)}</dd>
                  </div>
                </dl>
                }
                <p className="mt-1 text-base text-gray-500">Please choose a payment method below.</p>
                {deal.deal.allow_deposit == "true" && <p onClick={() => {switchToDeposit()}} className="mt-1 cursor-pointer mt-2 text-base text-blue-500">{!showDepositProduct ? "Pay Deposit Instead?" : "Pay In Full"}</p>}
              </div>

              <div className="mt-10">
                <button disabled={isLoading} onClick={(event) =>{event.preventDefault(); goToStripe() }} className="w-full btn btn-primary">
                <PulseLoader size="5" color="#000000" loading={isLoading}/>
                    {!isLoading && "Pay with Credit Card or ACH"}
                </button>
              </div>

              {deal.deal.allow_invoice == "true" &&
                <>
                  <div className="m-6 text-center text-base text-black">
                    <p className="text-lg">
                    &mdash; or &mdash;
                    </p>
                  </div>
                  <div className="mt-5">
                    <button
                      onClick={(event) =>{event.preventDefault(); showInvoiceForm() }}
                      className="w-full btn btn-primary"
                    >
                      Get Invoice for Wire Transfer
                    </button>
                  </div>
                </>
              }

            </section>
          </form>
          }
        </div>
      }
        </>
      </Container>
    )
  }

}
