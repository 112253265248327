import React, { useState } from 'react';
import RenewalMembership, { ActionBtn, RenewalMembershipProps } from './RenewalMembership';
import { Deal } from '../../types/deal.type';
import useStripeRenewalPayment from '../../hooks/stripe-renewal-payment';
import StripeProductIds from '../../constants/stripe-products';
import { RenewalLevel } from '../../constants/data.enums';
import { RequestInvoiceForm } from '../RequestInvoiceForm';

const PatronMembership: React.FC<{ deal: Deal }> = ({ deal }) => {
    const { initPayment, error } = useStripeRenewalPayment({ dealId: deal.hubspot_deal_id })
    const [showInvoice, setShowInvoice] = useState(false)
    const [btnStatuses, setBtnStatuses] = useState<Record<number, boolean>>({ 1: false, 2: false, 3: false, 4: false, 5: false })


    const patchBtnStatuses = (newState: Record<number, boolean>) => {
        setBtnStatuses((curState) => {
            return { ...curState, ...newState }
        })
    }

    const config: RenewalMembershipProps = {
        title: 'PATRON',
        renewalOptions: {
            priceAfterwards: '50,000',
            oneYearFee: '50,000',
            sixYearFee: '45,000'
        },
        commitTo: {
            discountedFee: '47,500',
            fee: '50,000',
            save: '2,500',
            year: '2026',
            actionBtns: [{
                text: 'SECURE YOUR SPOT NOW',
                submitting: btnStatuses[1],
                onClick() {
                    patchBtnStatuses({ 1: true });
                    initPayment(StripeProductIds[RenewalLevel.PATRON].id).finally(() => {
                        patchBtnStatuses({ 1: false });
                    })
                }
            }, ...(deal.allow_invoice === 'true' ? [{
                text: 'GET INVOICE FOR WIRE TRANSFER', submitting: btnStatuses[2], onClick() {
                    setShowInvoice(true)
                },
            }] : []) as ActionBtn[],
            ...(deal.allow_deposit === 'true' ? [
                {
                    text: 'SECURE YOUR SPOT WITH DEPOSIT NOW',
                    submitting: btnStatuses[4],
                    onClick() {
                        patchBtnStatuses({ 4: true });
                        initPayment(StripeProductIds[RenewalLevel.PATRON + 'Deposit'].id).finally(() => {
                            patchBtnStatuses({ 4: false });
                        })
                    }
                }
            ] : []) as ActionBtn[],
            ],
            desc: deal.allow_deposit === 'true' ? <div className='text-base'>
                <p>Patron and Fellow members with a 2025 membership start date after November 1, 2024 can submit a $10k nonrefundable deposit for a 2026 renewal with the balance due by October 15, 2025.</p>
                <p><a className='text-gold underline' href="https://www.abundance360.com/terms-25" target="_blank" rel="noopener noreferrer">See full terms and conditions here</a></p>
            </div> : <><p><a className='text-gold underline ' href="https://www.abundance360.com/terms-25" target="_blank" rel="noopener noreferrer">See full terms and conditions here</a></p></>
        },
        commitThru: {
            save: '25,000',
            year: '2030',
            membershipFee: <>
                Annual Membership Fee: <br />
                $45,000
                <br />
                <span className="font-normal">(Save <b className="font-semibold">$5K/yr</b> Total of <b className="font-semibold">$25K</b> Savings)</span>
            </>,
            actionBtns: [{
                text: 'SECURE YOUR SPOT NOW',
                submitting: btnStatuses[5],
                onClick: () => {
                    patchBtnStatuses({ 5: true });
                    initPayment(StripeProductIds[RenewalLevel.PATRON + 'Bundle'].id).finally(() => {
                        patchBtnStatuses({ 5: false });
                    })
                }
            },
            ...(deal.allow_deposit === 'true' ? [
                {
                    text: 'SECURE YOUR SPOT WITH DEPOSIT NOW',
                    submitting: btnStatuses[4],
                    onClick() {
                        patchBtnStatuses({ 4: true });
                        initPayment(StripeProductIds["PatronBundleDeposit"].id).finally(() => {
                            patchBtnStatuses({ 4: false });
                        })
                    }
                }
            ] : []) as ActionBtn[]],
            desc: <div className='text-base'>
                <p>5-Year Bundle: The membership dues for the first year of the 5-Year Bundle must be paid in full immediately, except for members with a 2025 membership start date after November 1, 2024 that can submit a $10k nonrefundable deposit for a 2026 renewal with the balance due by October 15, 2025. We accept only ACH or credit card payments. The subsequent payment is due on February 15, 2026, for the 2027 membership. Following that, payments will be due annually on February 15 before the following year’s Summit until 2030. You can cancel your Bundle at any time, but no refunds are given. Cancellation applies only to future membership years, not the current one, and previously paid benefits remain available. If you attend the Summit after canceling, you must pay the difference between the Bundle and standalone membership rates before gaining access. If you cancel and later rejoin, you may not be eligible for a Bundle and may need to pay the full membership fee at the then-current rate.</p>
                <p><a className='text-gold underline' href="https://www.abundance360.com/terms-25" target="_blank" rel="noopener noreferrer">See full terms and conditions here</a></p>
            </div>
        }
    }

    if (showInvoice) {
        return (<div className='mx-auto max-w-2xl px-4 py-16 sm:px-6 sm:py-12 lg:px-0'>
            <h1 className="text-center text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">Request Invoice for payment</h1>
            <RequestInvoiceForm deal={{ deal }} setShowInvoice={setShowInvoice} />
        </div>)
    } else {
        return <RenewalMembership {...config} paymentError={error} />
    }
}

export default PatronMembership