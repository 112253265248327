type StripeProduct = {
    readonly id: string
    readonly desc: string
}
const StripeProductIds: Readonly<Record<string, StripeProduct>> = {
    Virtual: { id: '99920784361071', desc: '2026 Annual Abundance360 Partner Membership' },
    VirtualBundle: { id: '20784361071', desc: '2026 Annual Abundance360 Partner Membership - Bundle' },
    GIP: { id: '99919385592988', desc: '2026 Annual Abundance360 Fellow Membership' },
    GIPBundle: { id: '20784361068', desc: '2026 Annual Abundance360 Fellow Membership - Bundle' },
    GIPDeposit: { id: '19406581390', desc: '2026 Annual Abundance360 Fellow Membership - Deposit' },
    GIPBundleDeposit: { id: '20989083751', desc: '2026 Annual Abundance360 Fellow 5Y Bundle - Deposit' },
    Patron: { id: '19385592992', desc: '2026 Annual Abundance360 Patron Membership' },
    PatronBundle: { id: '20784361065', desc: '2026 Annual Abundance360 Patron Membership - Bundle' },
    PatronDeposit: { id: '19406581397', desc: '2026 Annual Abundance360 Patron Membership - Deposit' },
    PatronBundleDeposit: { id: '20989083754', desc: '2026 Annual Abundance360 Patron 5Y Bundle - Deposit' },
}

export default StripeProductIds


