import React, { useState } from 'react';
import RenewalMembership, { RenewalMembershipProps } from './RenewalMembership';
import { Deal } from '../../types/deal.type';
import useStripeRenewalPayment from '../../hooks/stripe-renewal-payment';
import StripeProductIds from '../../constants/stripe-products';
import { RenewalLevel } from '../../constants/data.enums';

const VirtualMembership: React.FC<{ deal: Deal }> = ({ deal }) => {
    const { initPayment, error } = useStripeRenewalPayment({ dealId: deal.hubspot_deal_id })
    const [btnStatuses, setBtnStatuses] = useState<Record<number, boolean>>({ 1: false, 2: false })


    const patchBtnStatuses = (newState: Record<number, boolean>) => {
        setBtnStatuses((curState) => {
            return { ...curState, ...newState }
        })
    }

    const config: RenewalMembershipProps = {
        title: 'PARTNER',
        renewalOptions: {
            priceAfterwards: '11,000',
            oneYearFee: '10,500',
            sixYearFee: '10,000'
        },
        commitTo: {
            discountedFee: '10,500',
            fee: '11,000',
            save: '500',
            year: '2026',
            desc: <><p><a className='text-gold underline' href="https://www.abundance360.com/terms-25" target="_blank" rel="noopener noreferrer">See full terms and conditions here</a></p></>,
            actionBtns: [{
                text: 'SECURE YOUR SPOT NOW',
                submitting: btnStatuses[1],
                onClick: () => {
                    patchBtnStatuses({ 1: true });
                    initPayment(StripeProductIds[RenewalLevel.VIRTUAL].id).finally(() => {
                        patchBtnStatuses({ 1: false });
                    })
                }
            }]
        },
        commitThru: {
            save: '5,000',
            year: '2030',
            discountedFee: '10,000',
            fee: '11,000',
            desc: <><p>"5-Year Bundle: The membership dues for the first year of the 5-Year Bundle must be paid in full immediately, and we accept only ACH or credit card payments. The subsequent payment is due on February 15, 2026 for the 2027 membership. Following that, payments will be due annually on February 15 before the following year’s Summit until 2030. You can cancel your Bundle at anytime, but no refunds are given. Cancellation applies only to future membership years, not the current one, and previously paid benefits remain available. If you attend the Summit after canceling, you must pay the difference between the Bundle and standalone membership rates before gaining access. If you cancel and later rejoin, you may not be eligible for a Bundle and may need to pay the full membership fee at the then-current rate."</p>
            <p><a className='text-gold underline' href="https://www.abundance360.com/terms-25" target="_blank" rel="noopener noreferrer">See full terms and conditions here</a></p>
            </>,
            actionBtns: [{
                text: 'SECURE YOUR SPOT NOW',
                submitting: btnStatuses[2],
                onClick: () => {
                    patchBtnStatuses({ 2: true });
                    initPayment(StripeProductIds[RenewalLevel.VIRTUAL + 'Bundle'].id).finally(() => {
                        patchBtnStatuses({ 2: false });
                    })
                }
            }],
        }
    }

    return <RenewalMembership {...config} paymentError={error} />
}

export default VirtualMembership