// FormPage.tsx
import React, { useEffect, useState } from "react";
import { useForm, SubmitHandler } from "react-hook-form";
import {
  doc,
  getDoc,
  setDoc,
  DocumentData,
} from "firebase/firestore";
import {
  ref,
  uploadBytes,
  getDownloadURL,
} from "firebase/storage";
import { db as firestore, storage } from "./firebase.config"; // Import firestore and storage from firebase.config

// Define the type for our form values
interface FormValues {
  id: string;
  first_name: string;
  last_name: string;
  email: string;
  assistant_email: string;
  company: string;
  title: string;
  mobile_phone: string;
  can_share_phone: boolean;
  industry: string;
  bio: string;
  image: string; // this will hold the public URL
}

// The industry options
const industryOptions = [
  "Accounting",
  "Advertising",
  "Aerospace",
  "Agriculture",
  "Aircraft",
  "Airline",
  "Apparel & Accessories",
  "Automotive",
  "Banking",
  "Biotechnology",
  "Broadcasting",
  "Brokerage",
  "Call Centers",
  "Cargo Handling",
  "Chemical",
  "Computer",
  "Construction",
  "Consulting",
  "Consumer Products",
  "Cosmetics",
  "Defense",
  "Department Stores",
  "Education",
  "Electronics",
  "Energy",
  "Entertainment & Leisure",
  "Executive Search",
  "Financial Services",
  "Food, Beverage & Tobacco",
  "Grocery",
  "Health Care",
  "Human Performance",
  "Internet Publishing",
  "Investment Banking",
  "Legal",
  "Manufacturing",
  "Marketing",
  "Motion Picture & Video",
  "Music",
  "Newspaper Publishers",
  "Online Auctions",
  "Pension Funds",
  "Pharmaceuticals",
  "Private Equity",
  "Publishing",
  "Real Estate",
  "Retail & Wholesale",
  "Securities & Commodity Exchanges",
  "Service",
  "Soap & Detergent",
  "Software",
  "Sports",
  "Technology",
  "Telecommunications",
  "Television",
  "Transportation",
  "Trucking",
  "Venture Capital"
];

const FormPage: React.FC = () => {
  // Get route params; assumes a route like /form/:formid/prefill/:uid
  const urlParams = window.location.pathname.split('/');
  const formid = urlParams[2];
  const uid = urlParams[4];

  // react-hook-form setup
  const {
    register,
    handleSubmit,
    getValues,
    reset,
    formState: { errors },
  } = useForm<FormValues>();

  // Component state
  const [loading, setLoading] = useState(true);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [newImageFile, setNewImageFile] = useState<File | null>(null);
  const [submitted, setSubmitted] = useState(false);

  // Fetch prefill data on mount
  useEffect(() => {
    console.log("formid", formid);
    console.log("uid", uid);
    if (!formid || !uid) {
      setError("Page Not Found.");
      setLoading(false);
      return;
    }

    const docRef = doc(firestore, "forms", formid, "prefill", uid);
    getDoc(docRef)
      .then((docSnap) => {
        if (docSnap.exists()) {
          // Note: You might need to adjust this cast depending on your data shape.
          const data = docSnap.data() as DocumentData;
          // Pre-populate the form (even hidden id)
          reset(data);
        } else {
          setError("No data found.");
        }
        setLoading(false);
      })
      .catch((err) => {
        setError("Error fetching prefill data: " + err.message);
        setLoading(false);
      });
  }, [formid, uid, firestore, reset]);

  // Form submit handler
  const onSubmit: SubmitHandler<FormValues> = async (data) => {
    setSubmitLoading(true);
    try {
      let imageUrl = data.image; // use the prefill image URL by default

      // If the user selected a new image, upload it.
      if (newImageFile) {
        const imageRef = ref(
          storage,
          `forms/${formid}/image_${newImageFile.name}`
        );
        await uploadBytes(imageRef, newImageFile);
        imageUrl = await getDownloadURL(imageRef);
      }

      // Prepare the data to be saved.
      const submissionData = {
        ...data,
        image: imageUrl,
      };
      if (!formid || !uid) {
        throw new Error("Missing form id or user id.");
      }

      // Save the submission to Firestore at forms/{formid}/submissions/{uid}
      const submissionRef = doc(firestore, "forms", formid, "submissions", uid);
      await setDoc(submissionRef, submissionData);

      // Instead of showing an alert, set submitted to true so that the form fields disappear
      // and a success message is shown under the heading.
      setSubmitted(true);
    } catch (err: any) {
      setError("Error submitting form: " + err.message);
    }
    setSubmitLoading(false);
  };

  // Show a loading or error state if needed
  if (loading) {
    return (
      <div className="flex justify-center items-center h-screen text-yellow-500">
        Loading...
      </div>
    );
  }
  if (error) {
    return (
      <div className="flex justify-center items-center h-screen font-bold text-2xl text-red-500">
        {error}
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-black flex items-center justify-center p-4 py-10">
      <div className="bg-white rounded-lg shadow-lg p-8 w-full max-w-3xl">
        <h1 className="text-3xl text-left font-bold mb-6 text-yellow-500 uppercase">
          Patron Data Update
        </h1>
        {submitted ? (
          <div className="text-green-600 text-xl text-left font-semibold">
            Success! Your data has been updated.
          </div>
        ) : (
          <form onSubmit={handleSubmit(onSubmit)} className="space-y-4">
            {/* Hidden id field */}
            <input type="hidden" {...register("id")} />

            <div className="text-left">
              <label className="block text-left text-black font-semibold mb-1">
                First Name
              </label>
              <input
                className="w-full px-3 py-2 border rounded focus:outline-none focus:ring-2 focus:ring-yellow-500"
                type="text"
                {...register("first_name", { required: true })}
              />
              {errors.first_name && (
                <span className="text-red-500 text-lg text-left">
                  This field is required
                </span>
              )}
            </div>

            <div>
              <label className="block text-left text-black font-semibold mb-1">
                Last Name
              </label>
              <input
                className="w-full px-3 py-2 border rounded focus:outline-none focus:ring-2 focus:ring-yellow-500"
                type="text"
                {...register("last_name", { required: true })}
              />
              {errors.last_name && (
                <span className="text-red-500 text-lg text-left">
                  This field is required
                </span>
              )}
            </div>

            <div>
              <label className="block text-left text-black font-semibold mb-1">
                Email
              </label>
              <input
                className="w-full px-3 py-2 border rounded focus:outline-none focus:ring-2 focus:ring-yellow-500"
                type="email"
                {...register("email", { required: true })}
              />
              {errors.email && (
                <span className="text-red-500 text-lg text-left">
                  This field is required
                </span>
              )}
            </div>

            <div>
              <label className="block text-left text-black font-semibold mb-1">
                Assistant Email
              </label>
              <input
                className="w-full px-3 py-2 border rounded focus:outline-none focus:ring-2 focus:ring-yellow-500"
                type="email"
                {...register("assistant_email")}
              />
            </div>

            <div>
              <label className="block text-left text-black font-semibold mb-1">
                Company
              </label>
              <input
                className="w-full px-3 py-2 border rounded focus:outline-none focus:ring-2 focus:ring-yellow-500"
                type="text"
                {...register("company")}
              />
            </div>

            <div>
              <label className="block text-left text-black font-semibold mb-1">
                Title
              </label>
              <input
                className="w-full px-3 py-2 border rounded focus:outline-none focus:ring-2 focus:ring-yellow-500"
                type="text"
                {...register("title")}
              />
            </div>

            <div>
              <label className="block text-left text-black font-semibold mb-1">
                Mobile Phone
              </label>
              <input
                className="w-full px-3 py-2 border rounded focus:outline-none focus:ring-2 focus:ring-yellow-500"
                type="text"
                {...register("mobile_phone")}
              />
            </div>

            <div className="flex items-center">
              <input
                type="checkbox"
                id="can-share-phone"
                {...register("can_share_phone")}
                className="mr-2"
              />
              <label htmlFor="can-share-phone" className="text-black">
                Can we share your phone number with other patron members?
              </label>
            </div>

            <div>
              <label className="block text-left text-black font-semibold mb-1">
                Industry
              </label>
              <select
                className="w-full px-3 py-2 border rounded focus:outline-none focus:ring-2 focus:ring-yellow-500"
                {...register("industry", { required: true })}
              >
                <option value="">Select an industry</option>
                {industryOptions.map((option) => (
                  <option key={option} value={option}>
                    {option}
                  </option>
                ))}
              </select>
              {errors.industry && (
                <span className="text-red-500 text-lg text-left">
                  This field is required
                </span>
              )}
            </div>

            <div>
              <label className="block text-left text-black font-semibold mb-1">
                Bio
              </label>
              <textarea
                rows={10}
                className="w-full px-3 py-2 border rounded focus:outline-none focus:ring-2 focus:ring-yellow-500"
                {...register("bio")}
              />
            </div>

            <div>
              <label className="block text-left text-black font-semibold mb-1">
                Current Image
              </label>
              {/* Display the prefilled image if available and if no new file has been selected */}
              {dataHasImage() && (
                <img
                  src={
                    newImageFile
                      ? URL.createObjectURL(newImageFile)
                      : (getValues("image") as string)
                  }
                  alt="Prefilled"
                  className="w-64 h-64 object-cover mb-2 rounded"
                />
              )}
              <p className="text-left text-black font-semibold mb-1">
                Replace Image
              </p>
              <input
                className="block w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 cursor-pointer focus:outline-none"
                type="file"
                accept="image/*"
                onChange={(e) => {
                  if (e.target.files && e.target.files[0]) {
                    setNewImageFile(e.target.files[0]);
                  }
                }}
              />
            </div>

            <button
              type="submit"
              className="w-full py-2 px-4 bg-yellow-500 text-black font-semibold rounded hover:bg-yellow-400 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-yellow-500"
              disabled={submitLoading}
            >
              {submitLoading ? "Submitting..." : "Submit"}
            </button>
          </form>
        )}
      </div>
    </div>
  );

  // Helper to determine if there is an image to display.
  function dataHasImage() {
    // Using the react-hook-form getValues() to check if the image field is populated.
    // (Alternatively, you could manage prefill data in state.)
    const imageValue = (getValues("image") as string) || "";
    return imageValue.length > 0 || newImageFile;
  }
};

export default FormPage;
