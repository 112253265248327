import React from 'react';
import { PulseLoader } from 'react-spinners';
import AlertBox from '../AlertBox';
import cardBgImage from '../../assets/Summit_Renewal_Background.jpg';
// import whiteLogo from '../../assets/abundance360_white.svg';

export interface Commit {
    save: string
    year: string
    desc?: React.ReactNode;
    discountedFee?: React.ReactNode;
    fee?: React.ReactNode;
    membershipFee?: React.ReactElement
    actionBtns?: ActionBtn[]
}

export interface ActionBtn {
    text: string
    submitting?: boolean
    onClick: () => void
}

export interface RenewalMembershipProps {
    paymentError?: string
    title: string;
    renewalOptions: { priceAfterwards: string, oneYearFee: string, sixYearFee: string };
    commitTo: Commit;
    commitThru: Commit;
}

const RenewalMembership: React.FC<RenewalMembershipProps> = ({
    title,
    renewalOptions,
    commitTo,
    commitThru,
    paymentError
}) => {

    if (paymentError) {
        return (
            <AlertBox
                type='success'
                title={paymentError}
                message={''}
                actionBtns={{
                    postitiveBtnText: 'Login to AbundanceHub',
                    positiveBtnClick(e) {
                        e.preventDefault();
                        window.open('https://hub.a360.com/', '_blank');
                    },
                }}
            />
        );
    }

    return (
        <div className="mx-auto max-w-5xl px-4 py-8 md:px-6 md:py-12 lg:px-0 text-left rounded-lg">
            <div>
                <h1 className="text-2xl md:text-3xl font-bold mb-2 uppercase text-center">Renew Your <span className='text-gold'>{title}</span> Membership</h1>
            </div>
            <div className="mb-6 mt-8 bg-gray-200 px-4 md:px-10 py-6">
                <div className="flex flex-col md:flex-row gap-3 items-center justify-between mb-6">
                    <div className="rounded w-full md:flex-1 bg-cover object-cover bg-center bg-no-repeat" style={{backgroundImage: `url(${cardBgImage})`}}>
                        {/* <img src={cardBgImage} alt="" className="w-full" /> */}
                        <div className="w-full h-full px-4 md:px-10 py-6 md:py-8">
                            <div className="flex flex-col justify-between h-full gap-12">
                                <div className="flex flex-row items-center justify-between">
                                    <div className="">
                                        <h1 className="text-2xl md:text-6xl font-thin text-white uppercase">Commit thru {commitThru.year}</h1>
                                        <h3 className="text-lg md:text-4xl text-mediumGold italic font-medium">5-Year Bundle</h3>
                                    </div>
                                    <div className="self-start md:w-[40%]">
                                        {/* <img src={whiteLogo} alt="" title="" className="h-8" /> */}
                                    </div>
                                </div>
                                <div className="text-white text-lg md:text-2xl font-normal">
                                    {
                                        React.isValidElement(commitThru.membershipFee)
                                            ?
                                            <h3 className="mb-3">{commitThru.membershipFee}</h3>
                                            :
                                            <h3 className="mb-3">Annual Membership Fee: <br />
                                                ${commitThru.discountedFee} (<span className="line-through decoration-1">${commitThru.fee}</span>)
                                                <br />
                                                for next 5 years
                                                <br />
                                                <span className="font-semibold">(Save ${commitThru.save})</span>
                                            </h3>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="w-full md:flex-1 m-auto">
                        <ActionButtons actionBtns={commitThru.actionBtns ?? []} />
                    </div>
                </div>
                <p className="text-black mb-6 text-base">{commitThru.desc}</p>
            </div>
            <div className="mb-6 mt-8 bg-gray-200 px-4 md:px-10 py-4 md:py-10 rounded-lg">
                <div className="flex flex-col md:flex-row gap-3 items-center justify-between mb-6">
                    <div className="rounded w-full md:flex-1 bg-cover object-cover bg-center bg-no-repeat" style={{backgroundImage: `url(${cardBgImage})`}}>
                        {/* <img src={cardBgImage} alt="" className="w-full" /> */}
                        <div className="w-full h-full px-4 md:px-10 py-6 md:py-8">
                            <div className="flex flex-col justify-between h-full gap-12">
                                <div className="flex flex-row items-center justify-between">
                                    <div className="">
                                        <h1 className="text-2xl md:text-6xl font-thin text-white uppercase">Commit to {commitTo.year}</h1>
                                        <h3 className="text-lg md:text-4xl text-mediumGold italic font-medium">1-Year Renewal</h3>
                                    </div>
                                    <div className="self-start md:w-[40%]">
                                        {/* <img src={whiteLogo} alt="" title="" className="h-8" /> */}
                                    </div>
                                </div>
                                <div className="text-white text-lg md:text-2xl font-normal">
                                    {
                                        React.isValidElement(commitTo.membershipFee)
                                            ?
                                            <h3 className="mb-3">{commitTo.membershipFee}</h3>
                                            :<>
                                            <h3 className="mb-3">Membership Fee: <br />
                                                ${commitTo.discountedFee} (<span className="line-through decoration-1">${commitTo.fee}</span>)
                                            </h3>
                                            <span className="font-normal">(Save <b className="font-semibold">${commitTo.save}</b>)</span>
                                            </>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="w-full md:flex-1 m-auto">
                        <ActionButtons actionBtns={commitTo.actionBtns ?? []} />
                    </div>
                </div>
                <p className="text-black mb-6 text-base">{commitTo.desc}</p>
            </div>

        </div>
    );
};

const ActionButtons: React.FC<{ actionBtns: ActionBtn[] }> = ({ actionBtns }) => {
    return (
        <div className='grid grid-cols-1 auto-rows-fr gap-6'>
            {
                actionBtns?.map((actionBtn, idx) => <button key={idx} onClick={actionBtn.onClick} disabled={actionBtn.submitting} className="btn btn-primary w-full h-full m-auto lg:w-[75%] self-center justify-center text-white min-h-12 font-bold text-base md:text-xl rounded-md py-2 md:py-3 px-4 md:px-6">
                    <PulseLoader size="5px" color="#000000" loading={actionBtn.submitting} /> {!actionBtn.submitting && actionBtn.text}
                </button>)
            }
        </div>
    )
}

export default RenewalMembership;
